























































































































import {
  computed,
  defineComponent,
  ref,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import VaimoBadge from 'atoms/VaimoBadge.vue';
import VaimoButton from 'atoms/VaimoButton.vue';
import debounce from 'lodash.debounce';
import VaimoBooxiLifeTimeLabel from 'organisms/cart/parts/VaimoBooxiLifeTimeLabel.vue';
import VaimoCollectedProduct from 'organisms/cart/parts/VaimoCollectedProduct.vue';
import VaimoEngravingCard from 'organisms/cart/parts/VaimoEngravingCard.vue';
import VaimoQuantitySelector from 'organisms/VaimoQuantitySelector.vue';

import { useConfig, useUiState } from '~/composables';
import VaimoLoader from '~/diptyqueTheme/components/atoms/VaimoLoader.vue';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useLink } from '~/diptyqueTheme/composable/useLink';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCartStore } from '~/modules/checkout/stores/cart';
import {
  getBooxiLifetime,
  isVirtualCartItem
} from '~/diptyqueTheme/helpers/checkout/cartHelper';

export default defineComponent({
  name: 'VaimoCollectedProducts',
  components: {
    VaimoQuantitySelector,
    VaimoCollectedProduct,
    VaimoEngravingCard,
    VaimoBooxiLifeTimeLabel,
    SfLoader,
    VaimoBadge,
    VaimoLoader,
    VaimoButton
  },
  props: {
    isMiniCart: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { root }) {
    const { config } = useConfig();
    const { toggleCartSidebar } = useUiState();
    const { i18n, localePath, app } = useContext();
    const router = useRouter();
    const { normalizeLink } = useLink();
    const { booxiLifeTimeData } = useBooxi();
    const cartStore = useCartStore();
    const initialLoading = computed(() => cartStore.initial_loading);
    const isItemAdding = computed(() => cartStore.is_item_adding);

    const breadcrumbs = [
      {
        text: i18n.t('Home'),
        link: localePath({ name: 'home' })
      },
      {
        text: i18n.t('Basket'),
        link: localePath({ name: 'cart' })
      }
    ];
    const { cart, removeItem, updateItemQty, loading } = useCart();
    const products = computed(() =>
      cartGetters.getItems(cart.value).filter((product) => product)
    );

    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const UPDATE_QUANTITY_DEBOUNCE = 500;
    const updateQuantity = debounce(async ({ product, quantity }) => {
      if (lastRemovedByMinus.value === product.id) return;
      await updateItemQty({ product, quantity });
      root.$emit('reload-xmas-form');
    }, UPDATE_QUANTITY_DEBOUNCE);

    const lastRemovedByMinus = ref(null);
    const removeIfOne = ({ product, qty }, $event) => {
      if ($event.target.innerText === '−' && qty === 1) {
        lastRemovedByMinus.value = product.id;
        removeItem({ product });
      }
    };

    const isValidProductVariantName = (product) => {
      return (
        product?.product?.variant_name?.[0]?.label &&
        product.product.variant_name[0].label !== 'false'
      );
    };

    const { destroyBooxiReservation } = useBooxi();

    const removeItemFromCart = async (product) => {
      await removeItem({ product });
      if (isVirtualCartItem(product)) {
        destroyBooxiReservation();
      }
      app.$bus.$emit('remove-sample', product);
      const xmasSkus = config.value?.xmas_skus;
      if (
        Array.isArray(xmasSkus) &&
        xmasSkus.includes(cartGetters.getItemSku(product))
      ) {
        root.$emit('reload-xmas-form');
      }
    };

    const getProductPrice = (product) => {
      const cartProductPriceDisplayConfig =
        config.value?.tax_cart_display_price;
      return (
        (cartProductPriceDisplayConfig === '2'
          ? product.prices?.row_total_including_tax.value || 0
          : product.prices?.row_total.value) || 0
      );
    };

    const isOffertProduct = (price) => price === 0;

    const isSampleProduct = (product) => {
      const xmasSkus = config.value?.xmas_skus;
      if (
        Array.isArray(xmasSkus) &&
        xmasSkus.includes(cartGetters.getItemSku(product))
      ) {
        return false;
      }

      return cartGetters.getItemPrice(product).regular === 0;
    };

    const isEngraving = (product) => {
      return product.engraving ? true : false;
    };

    const getIsPreOrder = (product) => {
      //@ts-ignore
      return product.product.preorder;
    };

    const getPreOrderLabel = (product) => {
      //@ts-ignore
      return product.product.preorderStockNotice;
    };

    const getProductLink = (product) => {
      return normalizeLink(cartGetters.getSlug(product) as string);
    };

    const continueShopping = () => {
      if (props.isMiniCart) {
        toggleCartSidebar(null);
      } else {
        router.push(normalizeLink('/'));
      }
    };

    const getLoadingFlag = computed(
      () =>
        (totalItems.value && initialLoading.value) ||
        (!totalItems.value && initialLoading.value)
    );

    return {
      breadcrumbs,
      getLoadingFlag,
      isOffertProduct,
      cart,
      removeItem,
      updateItemQty,
      loading,
      removeIfOne,
      products,
      totalItems,
      updateQuantity,
      cartGetters,
      initialLoading,
      removeItemFromCart,
      isSampleProduct,
      isEngraving,
      validateFraction,
      getProductLink,
      getProductPrice,
      continueShopping,
      isItemAdding,
      getIsPreOrder,
      isValidProductVariantName,
      booxiLifeTimeData,
      getPreOrderLabel,
      isVirtualCartItem,
      getBooxiLifetime
    };
  }
});












































































import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import VaimoRadio from 'molecules/VaimoRadio.vue';

import LoyaltyPushRenderer from '~/diptyqueTheme/components/templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import useCart from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'UserAccount',
  components: {
    LoyaltyPushRenderer,
    VaimoRadio,
    SignInForm: () =>
      import('~/diptyqueTheme/modules/checkout/components/SignInForm.vue'),
    SignUpForm: () =>
      import('~/diptyqueTheme/modules/checkout/components/SignUpForm.vue'),
    GuestForm: () =>
      import('~/diptyqueTheme/modules/checkout/components/GuestForm.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  emits: ['on-hide-guest-form'],
  setup() {
    const selectedCard = ref<string>('');
    const isGuest = ref(false);
    const { app } = useContext();
    const { cart, load: loadCart } = useCart();
    const { getBeginCheckoutDetails, EVENT_TIMEOUT } = useGoogleTagManager();

    onMounted(() => {
      const mainLayout = document.getElementById('layout');
      if (mainLayout) mainLayout.classList.add('min-h-full');

      nextTick(() => {
        window.scroll(0, 0);
      });
    });

    onMounted(async () => {
      if (!cart.value.id) {
        await loadCart();
      }

      setTimeout(() => {
        app.$gtm.push(getBeginCheckoutDetails(cart.value));
      }, EVENT_TIMEOUT);
    });

    const applyCard = (type: string) => {
      selectedCard.value = type;
    };

    const showGuestForm = () => {
      isGuest.value = true;
    };

    const hideGuestForm = () => {
      isGuest.value = false;
    };

    return {
      selectedCard,
      applyCard,
      isGuest,
      showGuestForm,
      hideGuestForm
    };
  }
});











































































import {
  computed,
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoIcon from '~/diptyqueTheme/components/atoms/VaimoIcon.vue';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});
extend('required_email', {
  ...required,
  message: 'Please enter the email address.'
});

export default defineComponent({
  name: 'AccountForgotPasswordForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    SfInput,
    VaimoButton,
    VaimoIcon
  },
  emits: ['on-forgot-password'],
  setup(_, { emit }) {
    const {
      request: resetPassword,
      loading: forgotPasswordLoading,
      result
    } = useForgotPassword();
    const { i18n } = useContext();
    const email = ref('');
    const message = ref('');
    const emailSent = ref(false);
    const submit = async (invalid) => {
      if (invalid) {
        return false;
      }
      await resetPassword({ email: email.value });
      message.value = i18n.t('Email sent') as string;
      emailSent.value = true;
    };
    const onForgotPasswordCancel = () => {
      emit('on-forgot-password');
    };
    const reset = () => {
      emailSent.value = false;
      message.value = '';
    };

    const getSubtitleText = computed(() => {
      if (emailSent.value) {
        return i18n.t(
          'If there is an account registered with the {email} email, you will find message with a password reset link in your inbox.',
          {
            email: email.value
          }
        );
      }
      return i18n.t(
        'Please enter your email address below to receive the password reset link.'
      );
    });

    return {
      email,
      message,
      emailSent,
      forgotPasswordLoading,
      submit,
      onForgotPasswordCancel,
      reset,
      getSubtitleText
    };
  }
});



















































































import {
  computed,
  defineComponent,
  reactive,
  readonly,
  Ref,
  ref,
  useContext,
  useFetch,
  useRouter
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { useGuestUser } from '~/composables';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import useCart from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

export default defineComponent({
  name: 'GuestForm',
  components: {
    SfInput,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    ValidationProvider,
    ValidationObserver,
    VaimoLoader: () =>
      import('~/diptyqueTheme/components/atoms/VaimoLoader.vue')
  },
  scrollToTop: true,
  emits: ['on-hide-guest-form'],
  setup(_, { emit }) {
    const router = useRouter();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha
    );
    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser
    } = useGuestUser();

    const { cart } = useCart();

    const {
      load,
      loading: loadingUser,
      user,
      isAuthenticated,
      error: errorUser
    } = useUser();

    const isFormSubmitted = ref(false);
    const loading = computed(() => loadingUser.value || loadingGuestUser.value);

    const canMoveForward = computed(() => !loading.value);
    const guestErrors = computed(() => errorGuestUser.value.attachToCart);

    const queryLoading: Ref<boolean> = ref(false);
    const isLoading: Ref<boolean> = ref(false);
    const isAGuest = ref(false);

    type Form = {
      email: string;
      recaptchaToken?: string;
      recaptchaInstance?: string;
    };

    const form = reactive<Form>({
      email: ''
    });

    const handleFormSubmit = (reset: () => void) => async () => {
      isLoading.value = true;

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
        isLoading.value = false;
      }

      if (!isAuthenticated.value) {
        if (isRecaptchaEnabled.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          form.recaptchaToken = recaptchaToken;
          form.recaptchaInstance = $recaptcha;
        }

        await attachToCart({ email: form.email, cart });
      }

      if (isAGuest.value && !guestErrors.value) {
        await mergeItem('checkout', { 'user-account': form });
        await router.push(app.localeRoute({ name: 'shipping-address' }));
        reset();
        isFormSubmitted.value = true;
      } else {
        isLoading.value = false;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    const emailInputHandler = (input) => {
      if (input !== form.email) {
        form.email = input;
      }
    };

    const continueAsGuest = () => {
      isAGuest.value = true;
      window.scroll(0, 0);
      return true;
    };

    useFetch(async () => {
      if (user.value === null) {
        await load();
      }
      if (isAuthenticated.value) {
        form.email = user.value.email;
      }
    });

    const hideGuestForm = () => {
      emit('on-hide-guest-form');
    };

    return {
      canMoveForward,
      errorUser,
      form,
      handleFormSubmit,
      isAuthenticated,
      isFormSubmitted,
      loading,
      user,
      isRecaptchaEnabled,
      queryLoading: readonly(computed(() => queryLoading.value)),
      emailInputHandler,
      continueAsGuest,
      isLoading,
      isAGuest,
      hideGuestForm
    };
  }
});

<template>
  <div class="relative" :class="wrapperClasses">
    <div ref="breadcrumbsWrapper" class="breadcrumbs-wrapper">
      <SfBreadcrumbs
        v-if="preprocessedBreadcrumbs.length"
        class="breadcrumbs"
        :breadcrumbs="preprocessedBreadcrumbs"
      />
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import { throttle } from 'lodash-es';

import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'VaimoBreadcrumbs',
  components: {
    SfBreadcrumbs
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const breadcrumbsWrapper = ref(null);
    const scrollPosition = ref(0);
    const contentScrollWidth = ref(0);
    const RIGHT_FADE_THRESHOLD = 0.95;
    const SCROLL_THROTTLE_DELAY = 100;

    const preprocessedBreadcrumbs = computed(() =>
      props.breadcrumbs.map((b) => {
        b.link = normalizeLink(b.link);
        if (b.title && !b.text) {
          b.text = b.title;
        }
        return b;
      })
    );

    const showLeftFade = computed(() => scrollPosition.value > 0);
    const showRightFade = computed(
      () => scrollPosition.value < RIGHT_FADE_THRESHOLD
    );

    const wrapperClasses = computed(() => {
      return {
        'breadcrumbs-fade-left': showLeftFade.value,
        'breadcrumbs-fade-right': showRightFade.value
      };
    });

    const checkScroll = () => {
      const wrapper = breadcrumbsWrapper.value;
      contentScrollWidth.value = wrapper.scrollWidth - wrapper.offsetWidth;
      scrollPosition.value = wrapper.scrollLeft / contentScrollWidth.value;
    };

    onMounted(() => {
      breadcrumbsWrapper.value.addEventListener(
        'scroll',
        throttle(checkScroll, SCROLL_THROTTLE_DELAY)
      );
    });

    onUnmounted(() => {
      breadcrumbsWrapper.value.removeEventListener('scroll', checkScroll);
    });

    return {
      preprocessedBreadcrumbs,
      breadcrumbsWrapper,
      wrapperClasses
    };
  }
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  --breadcrumbs-list-item-before-padding: 0;
  max-width: var(--container-max-width);
  margin: 0 auto;
  @apply p-md;

  &-wrapper {
    position: relative;
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include for-screen-m-and-l {
    @apply px-lg;
  }

  ::v-deep {
    .sf-breadcrumbs {
      &__breadcrumb {
        @apply links;
      }

      &__breadcrumb.current {
        --link-color: var(--c-text);
        cursor: default
      }

      @include for-screen-s {
        --breadcrumbs-font-size: var(--font-size--sm);
      }

      &__list {
        flex-wrap: nowrap;

        &-item {
          @apply flex items-center;

          &:not(:last-child) {
            &:after {
              content: '';
              border-top: var(--spacer-3xs) solid transparent;
              border-bottom: var(--spacer-3xs) solid transparent;
              border-left: var(--spacer-xs) solid $gris;
              display: inline-block;
              margin: 0 var(--spacer-sm);
            }
          }

          &:last-child {
            padding: 0 var(--spacer-md) 0 0;
          }
        }
      }
    }
  }

  .current {
    @apply underline;
  }

  &-fade-left::before,
  &-fade-right::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 40px;
    z-index: 1;
  }

  &-fade-left::before {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &-fade-right::after {
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}
</style>
















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoHeaderSearch',
  components: {
    VaimoHeaderSearchBar: () =>
      import('~/integrations/klevu-search/components/VaimoHeaderSearchBar.vue')
  },
  props: {
    searchOnInit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search-close']
});

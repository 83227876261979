

































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  readonly,
  Ref,
  ref,
  useContext,
  useFetch,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { min, required } from 'vee-validate/dist/rules';

import { useCart, useConfig, useGuestUser } from '~/composables';
import isEmailAvailable from '~/diptyqueTheme/customQueries/magento/isEmailAvailable.gql';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import { useUser } from '~/modules/customer/composables/useUser';
import {
  customerPasswordRegExp,
  invalidPasswordMsg
} from '~/modules/customer/helpers/passwordValidation';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});
extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value)
});

export default defineComponent({
  name: 'SignInForm',
  components: {
    SfInput,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    ValidationProvider,
    ValidationObserver,
    VaimoLoader: () =>
      import('~/diptyqueTheme/components/atoms/VaimoLoader.vue'),
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    VaimoSocialLogin: () => import('organisms/VaimoSocialLogin.vue'),
    VaimoPasswordInput: () => import('organisms/VaimoPasswordInput.vue'),
    AccountForgotPasswordForm: () =>
      import('organisms/account/parts/AccountForgotPasswordForm.vue')
  },
  scrollToTop: true,
  setup() {
    const { config } = useConfig();
    const router = useRouter();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha
    );
    const { attachToCart } = useGuestUser();

    const {
      load,
      loading: loadingUser,
      login,
      user,
      isAuthenticated,
      error: errorUser
    } = useUser();

    const { cart } = useCart();

    const isFormSubmitted = ref(false);
    const unknownUser = ref(false);
    const isEmailChanged = ref(false);
    const loading = computed(() => loadingUser.value);

    const canMoveForward = computed(() => !loading.value);
    const userErrors = computed(
      () => errorUser.value.login || errorUser.value.register
    );

    const queryLoading: Ref<boolean> = ref(false);
    const isLoading: Ref<boolean> = ref(false);
    const isEmailEnabled = ref(false);
    const updateError = ref(null);
    const confirm_password_type = ref('password');
    const new_password_type = ref('password');
    const continueToShipping = ref(null);

    type Form = {
      email: string;
      password: string;
      recaptchaToken?: string;
      recaptchaInstance?: string;
      remember_me: boolean;
      activateNlValidation: boolean;
    };

    const form = reactive<Form>({
      email: '',
      password: '',
      remember_me: false,
      activateNlValidation: false
    });

    const selectType = (type) => {
      if (type === 'remember_me') {
        form.remember_me = !form.remember_me;
      }
    };

    const isSocialLoginEnabled = computed(
      () => config.value?.sociallogin_general_enabled
    );

    const checkPasswordFields = computed(() => {
      return !!form.password;
    });

    const handleFormSubmit = (reset: () => void) => async () => {
      isLoading.value = true;

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
        isLoading.value = false;
      }

      if (!isAuthenticated.value) {
        if (isRecaptchaEnabled.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          form.recaptchaToken = recaptchaToken;
          form.recaptchaInstance = $recaptcha;
        }

        await attachToCart({ email: form.email, cart });
      }

      if (!unknownUser.value) {
        const recaptchaParams: { recaptchaToken?: string } = {};
        if (isRecaptchaEnabled.value) {
          recaptchaParams.recaptchaToken = await $recaptcha.getResponse();
        }

        await login({
          user: {
            email: form.email,
            password: form.password,
            ...recaptchaParams
          }
        });
      }

      if (!userErrors.value) {
        form.password = '';
        await mergeItem('checkout', { 'user-account': form });
        await router.push(app.localeRoute({ name: 'shipping-address' }));
        reset();
        isFormSubmitted.value = true;
      } else {
        isLoading.value = false;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    interface ApiResponse {
      data?: {
        isEmailAvailable?: {
          is_email_available?: boolean;
        };
      };
    }

    const checkIsCustomerRegistered = async (): Promise<void> => {
      try {
        queryLoading.value = true;

        form.email.length > 0
          ? (isEmailChanged.value = true)
          : (isEmailChanged.value = false);

        const res: ApiResponse = await app.$vsf.$magento.api.customQuery({
          query: isEmailAvailable,
          queryVariables: {
            email: form.email
          }
        });

        isEmailEnabled.value =
          res.data?.isEmailAvailable?.is_email_available ?? false;

        unknownUser.value = !!res.data?.isEmailAvailable?.is_email_available;

        window.scroll(0, 0);
      } catch (e) {
        console.log(e);
      } finally {
        queryLoading.value = false;
      }
    };

    const emailInputHandler = (input) => {
      if (input !== form.email) {
        form.email = input;
      }
    };

    const changeInputType = (item) => {
      if (item === 'new_password') {
        new_password_type.value =
          new_password_type.value === 'text' ? 'password' : 'text';
      }
    };

    const onForgotPassword = () => {
      isForgotPasswordStep.value = !isForgotPasswordStep.value;
    };
    const isForgotPasswordStep = ref(false);
    const changeStep = () => {
      isForgotPasswordStep.value = !isForgotPasswordStep.value;
    };

    useFetch(async () => {
      if (user.value === null) {
        await load();
      }
      if (isAuthenticated.value) {
        form.email = user.value.email;
      }
    });

    onMounted(async () => {
      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account']) {
        const data = checkout['user-account'];
        form.email = data.email;
      }

      if (isAuthenticated.value && canMoveForward.value) {
        continueToShipping.value?.children?.[0] &&
          continueToShipping.value.children[0].click();
      } else {
        watch(canMoveForward, () => {
          continueToShipping.value?.children?.[0] &&
            continueToShipping.value.children[0].click();
        });
      }
    });

    return {
      canMoveForward,
      errorUser,
      form,
      handleFormSubmit,
      isAuthenticated,
      isFormSubmitted,
      loading,
      unknownUser,
      isEmailChanged,
      user,
      isRecaptchaEnabled,
      checkIsCustomerRegistered,
      queryLoading: readonly(computed(() => queryLoading.value)),
      isEmailEnabled,
      emailInputHandler,
      checkPasswordFields,
      updateError,
      confirm_password_type,
      new_password_type,
      changeInputType,
      isLoading,
      continueToShipping,
      onForgotPassword,
      isForgotPasswordStep,
      changeStep,
      selectType,
      isSocialLoginEnabled
    };
  }
});

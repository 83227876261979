var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newsletter-subscription",class:{ animate: _vm.expanded }},[(!_vm.hideHeader)?_c('VaimoHeading',{staticClass:"newsletter-subscription__heading",attrs:{"heading-level":"h3","heading-style":"emphasized","heading":_vm.$t('LET’S STAY IN TOUCH'),"sub-heading":_vm.$t(
        'Please subscribe to our newsletter so that we can let you know about the latest creations from the Maison, send you invitations to Diptyque events and offer you thoughtful, well-chosen gifts.'
      ),"sub-heading-level":"p","animated-heading":false,"animated-description":false}}):_vm._e(),_vm._v(" "),(_vm.showForm)?[_c('ValidationObserver',{staticClass:"form-container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
      var reset = ref.reset;
      var invalid = ref.invalid;
return [_c('form',{staticClass:"form",class:{ 'form--expanded': _vm.expanded },on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleSubscribe(reset))}}},[_c('ValidationProvider',{attrs:{"rules":"required|email_ex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var failedRules = ref.failedRules;
return [_c('SfInput',{staticClass:"form__element center input--with-label form__element--email",class:{ 'pt-0': !_vm.inputLabel },attrs:{"error-message":_vm.$t(errors[0]),"valid":!errors[0],"disabled":_vm.loading || _vm.subscribeStatus === 'success',"type":"email","name":"email","placeholder":_vm.$t(
                _vm.isStoreDe ? 'My E-mail address' : 'Enter your email address …'
              ),"label":_vm.inputLabel,"aria-error-message":_vm.getAriaLabel(failedRules, 'E-mail')},on:{"click":_vm.handleEmailInputClick},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-feedback",class:{ success: _vm.subscribeStatus === 'success' }},[(_vm.subscribeStatus)?_c('div',{attrs:{"role":"alert","aria-live":"polite"}},[(_vm.subscribeStatus === 'success')?_c('VaimoIcon',{staticClass:"checkmark",attrs:{"icon":"check","label":_vm.$t('Checkmark'),"size":9}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.subscribeStatusMsg)+"\n          ")],1):_vm._e()]),_vm._v(" "),(_vm.expanded)?_c('div',{staticClass:"animate-content"},[_c('SubscriptionLegalNotice',{staticClass:"legal-notice"}),_vm._v(" "),_c('VaimoButton',{staticClass:"newsletter-button",attrs:{"variant":"default","label-centered":true,"full-width":true,"data-testid":"newsletter-button"},nativeOn:{"click":function($event){_vm.handleSubscribe(_vm.email, invalid),
                _vm.getClickFooterNewsletterDetails()}}},[_vm._v("\n            "+_vm._s(_vm.submitButtonText || _vm.$t('Subscribe'))+"\n          ")])],1):_vm._e()],1)]}}],null,false,2067642735)})]:_c('p',{staticClass:"mt-3md text-center"},[_vm._v("\n    "+_vm._s(_vm.$t('We will send you a link to confirm your registration.'))+"\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }



























































































import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import { SfAccordion, SfButton } from '@storefront-ui/vue';
import { useMediaQuery } from '@vueuse/core';

import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'VaimoFooterPleatPanels',
  components: {
    VaimoFooterPleatsPanelBlock: () =>
      import('organisms/footer/PleatsPanel/Block.vue'),
    SfAccordion,
    SfButton
  },
  setup() {
    const {
      query: footerPleatsPanelQuery,
      queryResponse: footerPleatsPanelEntries
    } = useContentfulGQL('byIdFooterPanel');
    const {
      app: { i18n }
    } = useContext();

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const accordionKey = ref(0);
    const footerPleatPanels = ref([]);

    useFetch(async () => {
      await footerPleatsPanelQuery('getMegaMenu', {
        type: 'Footer',
        storeview: i18n.localeProperties.storeview
      });
      const items =
        footerPleatsPanelEntries.value?.megaMenuCollection?.items[0]
          ?.layersCollection?.items ?? [];
      const getMenuLayersList = items.reduce((memo, cur) => {
        if (cur && cur.layerContentCollection?.items?.length) {
          memo.push({
            id: cur.sys?.id,
            data: [...cur.layerContentCollection.items]
          });
        }
        return memo;
      }, []);

      footerPleatPanels.value = items.reduce((memo, cur) => {
        if (cur) {
          memo.push({
            text: cur.topMenuItemText,
            link: cur.topMenuItemLink,
            id: cur.sys?.id,
            layerData: getMenuLayersList.filter(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (layer) => layer.id === cur.sys?.id
            )
          });
        }
        return memo;
      }, []);
    });

    watch(isDesktop, () => {
      accordionKey.value++;
    });

    return {
      footerPleatPanels,
      isDesktop,
      accordionKey
    };
  }
});

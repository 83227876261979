






























































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import VaimoMenuPush from 'organisms/header/mobile/VaimoMenuPush.vue';

import { useUiState, useUser } from '~/composables';
import { getHelpLink } from '~/diptyqueTheme/helpers/helpLinks';
import { useAccountStore } from '~/diptyqueTheme/stores/account';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';

export default defineComponent({
  name: 'VaimoHeaderNavigation',
  components: {
    VaimoMenuPush,
    SfButton,
    VaimoStoreSwitcher: () =>
      import('organisms/VaimoStoreSwitcher/VaimoStoreSwitcher.vue')
  },
  props: {
    menuData: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  emits: ['change-navigation'],

  setup(props, { emit }) {
    const {
      app: { i18n, $gtm, localePath }
    } = useContext();
    const helpLink = getHelpLink();
    const { toggleCartSidebar } = useUiState();
    const { isAuthenticated } = useUser();
    const accountStore = useAccountStore();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const isMobileMenuVisible = computed(() => megaMenuMobileStore.open);

    const toggleAccountSidebar = () => {
      if (isAuthenticated.value && megaMenuMobileStore.open) {
        accountStore.isVisible = true;
        megaMenuMobileStore.open = false;
      } else {
        if (megaMenuMobileStore.open) {
          megaMenuMobileStore.open = false;
          toggleCartSidebar('account');
        }
      }
    };

    interface Setting {
      id: number;
      icon: string;
      text: string;
      link: string;
    }

    const settings: Setting[] = [
      {
        text: i18n.t('Find a store') as string,
        id: 2,
        icon: 'shop',
        link:
          'https://stores.diptyqueparis.com' +
          localePath('/').split('_').shift()
      },
      {
        text: i18n.t('Need help?') as string,
        id: 3,
        icon: 'helpcenter',
        link: helpLink
      }
    ];

    const getLanguage = computed<string>(() => {
      // todo: get langMap from i18n
      const langMap = {
        fr_fr: 'France',
        de_ch: 'Germany'
      };

      return langMap[i18n.locale] || 'France';
    });

    const isDivider = (item) => item.__typename === 'Divider';

    const navigationDividerPosition = computed<number>(() =>
      mobileNavigationItems.value.findIndex(isDivider)
    );

    const showDivider = (index: number): boolean =>
      index + 1 === navigationDividerPosition.value;

    const mobileNavigationItems = computed(
      () =>
        props.menuData?.megaMenu?.layersCollection?.items?.filter(
          (item) => !hideOnMobile(item)
        ) || []
    );

    const navigationItems = computed(() => {
      return mobileNavigationItems.value
        .filter((item) => !isDivider(item))
        .map((item) => ({
          text: item.topMenuItemText,
          link: item.topMenuItemLink,
          id: item.sys?.id
        }));
    });

    const hideOnMobile = (item): boolean => {
      return item?.hideOnMobile as boolean;
    };

    const change = (item: { text: string; id: string; link: string }) => {
      emit('change-navigation', item);
      $gtm.push({
        event: 'clickNav',
        mainLevel: item.text
      });
    };

    const getClickAccountDetails = () => {
      $gtm.push({
        event: 'clickAccount',
        connected: isAuthenticated.value ? 'Yes' : 'No'
      });
    };

    return {
      change,
      settings,
      navigationItems,
      getLanguage,
      isAuthenticated,
      showDivider,
      toggleAccountSidebar,
      getClickAccountDetails,
      isMobileMenuVisible
    };
  }
});

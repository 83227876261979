






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ExternalCart',
  scrollToTop: true,
  setup() {
    return {};
  }
});

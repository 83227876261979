var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step__guest__wrapper",attrs:{"data-cs-capture":""}},[_c('ValidationObserver',{staticClass:"step__guest",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('form',{staticClass:"guest-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();validate().then(handleSubmit(_vm.handleFormSubmit(reset)))}}},[_c('VaimoLoader',{class:{ loader: _vm.loading || _vm.isLoading },attrs:{"loading":_vm.isLoading}}),_vm._v(" "),_c('div',{staticClass:"form"},[_c('ValidationProvider',{staticClass:"form__element-wrapper",attrs:{"name":"email","rules":"email_ex|required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-email'),expression:"'user-account-email'"}],staticClass:"form__element input--with-label",attrs:{"value":_vm.form.email,"label":_vm.$t('Email address'),"type":"email","name":"email","required":"","placeholder":' ',"valid":!errors[0],"error-message":_vm.$t(errors[0]),"autocomplete":"username"},on:{"input":function($event){return _vm.emailInputHandler($event)}},scopedSlots:_vm._u([{key:"error-message",fn:function(){return [_c('div',{class:{ 'display-none': !errors[0] },attrs:{"id":"email-error","aria-live":"assertive","data-cs-capture":""}},[_c('span',{staticClass:"sf-input__error-message-icon",attrs:{"aria-hidden":"true"}}),_vm._v("\n                "+_vm._s(_vm.$t(errors[0]))+"\n              ")])]},proxy:true}],null,true)})]}}],null,true)})],1),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form__action-wrapper"},[_c('div',{staticClass:"form__action form__action-continue-as-guest block"},[_c('VaimoButton',{staticClass:"form__action-button",attrs:{"variant":"primary","type":'submit',"disabled":!_vm.canMoveForward},on:{"click":_vm.continueAsGuest}},[_vm._v("\n            "+_vm._s(_vm.$t('Continue as guest'))+"\n          ")]),_vm._v(" "),_c('VaimoButton',{staticClass:"form__action-back",attrs:{"variant":"link","aria-labelledBy":_vm.$t('Back')},on:{"click":_vm.hideGuestForm}},[_vm._v("\n            "+_vm._s(_vm.$t('Back'))+"\n          ")])],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }













































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  ssrRef,
  useContext,
  useFetch,
  useMeta,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import debounce from 'lodash.debounce';
import VaimoCollectedProducts from 'organisms/cart/parts/VaimoCollectedProducts.vue';

import CouponCode from '~/components/CouponCode.vue';
import { useConfig, useStore, useUiNotification } from '~/composables';
import VaimoSpecialOfferProducts from '~/diptyqueTheme/components/organisms/cart/parts/VaimoSpecialOfferProducts.vue';
import VaimoCartGiftMessage from '~/diptyqueTheme/components/organisms/VaimoGiftMessage/VaimoCartGiftMessage.vue';
import VaimoGiftWrapping from '~/diptyqueTheme/components/organisms/VaimoGiftWrapping/VaimoGiftWrapping.vue';
import { useGoogleTagManager, useNarVar } from '~/diptyqueTheme/composable';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useNosto } from '~/diptyqueTheme/composable/useNosto';
import contentfulEntriesIds from '~/diptyqueTheme/config/contentfulEntries';
import { getOfferProducts } from '~/diptyqueTheme/getters/offerProductsGetter';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import Totals from '~/diptyqueTheme/modules/checkout/components/Totals.vue';
import { useXmas } from '~/diptyqueTheme/modules/xmas/composables/useXmas';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import CartPreview from '~/modules/checkout/components/CartPreview.vue';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCartStore } from '~/modules/checkout/stores/cart';
import useUser from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'CartPage',
  components: {
    CouponCode,
    CartPreview,
    SfHeading,
    VaimoCollectedProducts,
    VaimoCartGiftMessage,
    VaimoSpecialOfferProducts,
    VaimoGiftWrapping,
    Totals,
    UpsellProducts: () => import('templates/sections/ProductSlider/index.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SampleProducts: () => import('organisms/miniCart/parts/SampleProducts.vue'),
    AdyenApplePayPeymentProvider: () =>
      import(
        '~/integrations/adyen/components/AdyenApplePayPeymentProvider.vue'
      ),
    EngravingLegalNotice: () =>
      import('organisms/product/EngravingLegalNotice.vue')
  },
  scrollToTop: true,
  setup() {
    const { i18n, app, route } = useContext();
    const { sendBooxiExpiredMessage, checkIsBooxiExpired } = useBooxi();
    const { query, queryResponse } = useContentfulGQL('byIdUpSellProducts');
    const {
      cart,
      removeItem,
      updateItemQty,
      loading,
      load,
      loadCartStockStatus
    } = useCart();
    const { user } = useUser();
    const products = computed(() => cartGetters.getItems(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const UPDATE_QUANTITY_DEBOUNCE = 500;
    const updateQuantity = debounce(async ({ product, quantity }) => {
      await updateItemQty({ product, quantity });
    }, UPDATE_QUANTITY_DEBOUNCE);
    const upSellProductsData = ssrRef(null);
    const { getEstimatedDeliveryDate, estimatedDeliveryDate } = useNarVar();
    const { getShowCartpageDetails } = useGoogleTagManager();
    const showCartGiftMessage = ref(false);
    const { updateNostoSession, recommendationTypes } = useNosto();
    const { getAllowedCountries, loadCountriesList } = useCountriesStore();
    const router = useRouter();
    const countriesLoading = ref(false);
    const applePayInitiated = ref(false);
    const disableRegularButton = ref(true);
    const shouldDisableRegularButton = computed(
      () => app.$device.isSafari && disableRegularButton.value
    );
    const applePayIsAvailable = computed(
      () => app.$device.isSafari && applePayInitiated.value
    );
    const applePayPopupOpened = ref(false);
    const { updateQty: xmasUpdateBagsQty } = useXmas();
    const { addBooxiProductToCart } = useBooxi();
    const { booking: booxiData } = route.value.query;
    const cartStore = useCartStore();
    const { hasOneBooxiItemInCart } = useBooxi();
    const { config } = useConfig();
    const { isJpStore } = useStore();

    const isXmasEnabled = computed(() => config.value.xmas_enable);

    const { send: sendNotification } = useUiNotification();

    const transformUpsellsData = (data) => {
      if (!data || !data.productGroup) return null;
      return {
        heading: data.heading,
        productGroupsCollection: {
          items: [data.productGroup]
        }
      };
    };

    const applePayWasInitiated = (result): void => {
      applePayInitiated.value = result;
      disableRegularButton.value = result;
    };

    const setApplePayWasTriggered = () => (disableRegularButton.value = true);

    const toggleVariationsPopup = (): void => {
      applePayPopupOpened.value = !applePayPopupOpened.value;
    };
    const isDesktop = computed(() => app.$device.isDesktop);

    const applePayMainButtonAdditionalClasses = 'main-applepay';

    onMounted(async () => {
      if (!cart.value.id) {
        await load();
      }
      if (booxiData) {
        await addBooxiProductToCart(booxiData as string);
      }
      await getEstimatedDeliveryDate(cart.value.id);
      await updateNostoSession(recommendationTypes.cart, route.value.fullPath);
      await loadCartStockStatus({ cartId: cart.value.id });
      upSellProductsData.value = transformUpsellsData(
        queryResponse.value?.collectionPushCollection?.items[0] ?? null
      );

      nextTick(() => {
        window.scroll(0, 0);
        app.$gtm.push(getShowCartpageDetails(cart.value));
      });
    });

    useFetch(async () => {
      countriesLoading.value = true;
      await Promise.all([getAllowedCountries(), loadCountriesList()]);
      countriesLoading.value = false;

      await query('getUpsellProductsByKey', {
        key: contentfulEntriesIds.CART_UPSELL_PRODUCTS_KEY,
        locale: i18n.localeProperties.contentfulLocale
      });
    });

    const getEstimatedDeliveryDataText = computed(() => {
      if (estimatedDeliveryDate.value) {
        return i18n.t('Order today and receive your items by {date}', {
          date: estimatedDeliveryDate.value?.split('-').reverse().join('/')
        });
      }
    });

    const hasSpecialOffers = computed(() => {
      return totalItems.value && getOfferProducts(cart.value).length;
    });

    const toggleCartGiftMessage = async (giftWrapping) => {
      showCartGiftMessage.value = giftWrapping && !giftWrapping?.is_default;
      if (!showCartGiftMessage.value && isXmasEnabled.value) {
        await xmasUpdateBagsQty(0);
      }
    };

    const goToCheckout = async () => {
      if (checkIsBooxiExpired()) {
        sendBooxiExpiredMessage();
        return;
      }
      if (countriesLoading.value) {
        //dont allow proceeding to checkout until countries are finished loading
        return;
      }

      if (user.value) {
        await mergeItem('checkout', { 'user-account': user.value });
      }

      window.scrollTo(0, 0);

      const url = user.value
        ? '/checkout/shipping-address'
        : '/checkout/user-account';

      await router.push(`${app.localePath(`${url}`)}`);
    };

    const metaTags = computed(() => ({
      title: `${i18n.t('Cart')} | Diptyque Paris`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${i18n.t('Cart')} | Diptyque Paris`
        }
      ]
    }));

    useMeta(metaTags.value);

    watch(
      () => cartStore.cartStockStatus?.items,
      (newItems) => {
        if (!newItems) return;

        const outOfStockItems = newItems.find(
          (item) => item?.product?.stock_status === 'OUT_OF_STOCK'
        );

        if (outOfStockItems) {
          sendNotification({
            id: Symbol('has_out_of_stock_items'),
            message: i18n.t('Some of the products are out of stock.') as string,
            persist: false,
            type: 'danger',
            icon: 'cross'
          });
        }
      },
      { immediate: true, deep: true }
    );

    return {
      cart,
      removeItem,
      getEstimatedDeliveryDataText,
      updateItemQty,
      upSellProductsData,
      loading,
      products,
      totalItems,
      hasSpecialOffers,
      updateQuantity,
      cartGetters,
      validateFraction,
      showCartGiftMessage,
      toggleCartGiftMessage,
      countriesLoading,
      goToCheckout,
      applePayWasInitiated,
      applePayMainButtonAdditionalClasses,
      applePayIsAvailable,
      toggleVariationsPopup,
      applePayPopupOpened,
      isDesktop,
      disableRegularButton,
      setApplePayWasTriggered,
      hasOneBooxiItemInCart,
      shouldDisableRegularButton,
      isBooxiLoading: computed(() => cartStore.is_booxi_loading),
      isJpStore
    };
  },

  head() {
    return {
      bodyAttrs: {
        class: 'has-sticky cart-page'
      }
    };
  }
});

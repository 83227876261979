












































































































import type { Ref } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  provide,
  ref,
  toRef,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import {
  SfAccordion,
  SfButton,
  SfFilter,
  SfRadio,
  SfSidebar
} from '@storefront-ui/vue';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUiHelpers } from '~/composables';
import { pluralize } from '~/diptyqueTheme/helpers/pluralize';
import { useFiltersStore } from '~/diptyqueTheme/stores/filters';
import { getProductFilterByCategoryCommand } from '~/modules/catalog/category/components/filters/command/getProductFilterByCategoryCommand';
import SelectedFilters from '~/modules/catalog/category/components/filters/FiltersSidebar/SelectedFilters.vue';
import SortWrapper from '~/modules/catalog/category/components/sort/SortWrapper.vue';
import { getFilterConfig } from '~/modules/catalog/category/config/FiltersConfig';
import type { Aggregation } from '~/modules/GraphQL/types';

import type { SelectedFiltersInterface } from './useFilters';
import { useFilters } from './useFilters';

export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>;
  filters: Ref<Aggregation[]>;
}

export default defineComponent({
  name: 'CategoryFilters',
  components: {
    SelectedFilters,
    SkeletonLoader,
    SfSidebar,
    SortWrapper,
    CheckboxType: () =>
      import(
        '~/modules/catalog/category/components/filters/renderer/CheckboxType.vue'
      ),
    SwatchColorType: () =>
      import(
        '~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue'
      ),
    RadioType: () =>
      import(
        '~/modules/catalog/category/components/filters/renderer/RadioType.vue'
      ),
    YesNoType: () =>
      import(
        '~/modules/catalog/category/components/filters/renderer/YesNoType.vue'
      ),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue'),
    VaimoQuickFilterNav: () =>
      import('organisms/category/VaimoQuickFilterNav.vue'),
    SfAccordion,
    SfFilter,
    SfButton,
    SfRadio
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    productsAmount: {
      required: false,
      default: 0,
      type: Number
    },
    catUid: {
      type: String,
      required: true
    },
    catId: {
      type: Number,
      required: true
    },
    sortBy: {
      required: false,
      default: () => ({ nosto_personalized: 'ASC' }),
      type: Object
    },
    aggregations: {
      required: false,
      default: () => [],
      type: Array
    },
    loading: Boolean
  },
  emits: ['reloadProducts', 'close'],
  setup(props, { emit, refs }) {
    const {
      app: { i18n, $gtm }
    } = useContext();

    const { changeFilters, clearFilters } = useUiHelpers();
    const removableFilters = ref([]);
    const filters = ref<Aggregation[]>([]);
    const isLoading = ref(true);
    const isFilterBlockVisible = ref<boolean>(false);
    const isShowMoreVisible = ref<boolean>(false);
    const isAllSelected = ref(null);
    const aggregations = toRef(props, 'aggregations');
    let {
      selectedFilters,
      selectFilter,
      removeFilter,
      isFilterSelected,
      getRemovableFilters,
      lastSelectedFilter
    } = useFilters(props.catId, aggregations);

    const filtersStore = useFiltersStore();

    nextTick(() => {
      watch(selectedFilters.value, () => {
        updateRemovableFilters();
      });
    });

    const changeFilterVisibility = () => {
      const searchBarDesktop = document.querySelector<HTMLElement>(
        '.top-header__wrapper > .search-bar'
      );

      if (!isFilterBlockVisible.value) {
        if (searchBarDesktop) {
          searchBarDesktop.style.zIndex = '0';
        }
        document.body.classList.add('sidebar-open');
        document.body.classList.add('no-scroll');
        setTimeout(() => {
          isFilterBlockVisible.value = true;
        }, 500);
      } else {
        isFilterBlockVisible.value = false;
        setTimeout(() => {
          document.body.classList.remove('sidebar-open');
          document.body.classList.remove('no-scroll');
          if (searchBarDesktop) {
            searchBarDesktop.style.zIndex = '700';
          }
        }, 500);
      }
    };

    const getButtonAmountMessage = computed(() => {
      const amount =
        selectedFilters.value &&
        Object.values(selectedFilters.value).reduce(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (memo, cur) => memo + cur.length,
          0
        );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return amount ? ` (${amount} ${i18n.t(pluralize(amount, 'filter'))})` : '';
    });

    const updateRemovableFilters = () => {
      removableFilters.value = getRemovableFilters(
        filters.value,
        selectedFilters.value
      );
    };

    const clearSorting = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // todo: fix type
      refs.sortWrapperRef.resetSorting();
    };

    const doApplyFilters = () => {
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const resetCategory = (data) => {
      data.filter_arr?.forEach((item) => {
        doRemoveFilter({ id: data.code, value: item });
      });
    };

    const doRemoveFilter = ({ id, value }: { id: string; value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      filtersStore.currentFilterCache = {};
      filtersStore.lastSelectedFilter = {};
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const doClearFilters = () => {
      isAllSelected.value = false;
      for (const selected in selectedFilters.value) {
        for (const optionId in selectedFilters.value[selected]) {
          removeFilter(selected, selectedFilters.value[selected][0]);
        }
      }
      filtersStore.currentFilterCache = {};
      filtersStore.lastSelectedFilter = {};
      clearFilters(false);
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const showMore = () => {
      isShowMoreVisible.value = !isShowMoreVisible.value;
    };

    watch(
      () => props.isVisible,
      (newValue) => {
        // Disable Storefront UI's body scroll lock which is launched when :visible prop on SfSidebar changes
        // two next ticks because SfSidebar uses nextTick as well, and we want to do something after that tick.
        if (newValue) {
          nextTick(() => nextTick(() => clearAllBodyScrollLocks()));
        }
      }
    );

    const getClickFilterDetails = (filter, event) => {
      $gtm.push({
        event: 'clickFilter',
        filterType: filter.label,
        filterValue: event.label
      });
    };

    onMounted(async () => {
      const loadedFilters = await getProductFilterByCategoryCommand.execute({
        eq: props.catUid
      });
      const excludedFields = ['category_id'];
      filters.value = loadedFilters.filter(
        (filter) => !excludedFields.includes(filter.attribute_code)
      );
      updateRemovableFilters();
      isLoading.value = false;
    });

    const getAggregationMap = computed(() => {
      return props.aggregations.reduce((memo, cur) => {
        // @ts-ignore
        memo[cur.attribute_code] = cur;
        return memo;
      }, {});
    });

    const mergedFiltersOptions = computed(() => {
      const newFilters = filters.value.map((filter) => {
        if (!props.aggregations?.length) {
          return filter;
        }

        if (getAggregationMap.value[filter.attribute_code]) {
          const aggregation = getAggregationMap.value[filter.attribute_code];
          const optionsWithMissedOptions = filter.options.reduce(
            (acc, option) => {
              if (!aggregation.options.find((o) => o.value === option.value)) {
                acc.push({ ...option, count: 0 });
              }
              return acc;
            },
            [...aggregation.options]
          );

          return {
            ...filter,
            options: optionsWithMissedOptions
          };
        }
        const optionsWithZeroCount = filter.options.map((option) => ({
          ...option,
          count: 0
        }));
        return { ...filter, options: optionsWithZeroCount };
      });

      return newFilters;
    });

    const updateFocus = () => {
      // Accessibility support: recreate focus area
      const sidebar = document.querySelector('aside.sf-sidebar__aside');
      sidebar && focusTrap.componentUpdated(sidebar);
    };

    provide('UseFiltersProvider', {
      isFilterSelected,
      doApplyFilters,
      selectedFilters,
      filters
    });

    return {
      selectFilter,
      clearSorting,
      doRemoveFilter,
      doClearFilters,
      isAllSelected,
      getFilterConfig,
      selectedFilters,
      isFilterBlockVisible,
      getButtonAmountMessage,
      changeFilterVisibility,
      updateRemovableFilters,
      resetCategory,
      filters,
      isShowMoreVisible,
      showMore,
      isLoading,
      removableFilters,
      mergedFiltersOptions,
      lastSelectedFilter,
      getAggregationMap,
      getClickFilterDetails,
      updateFocus
    };
  }
});

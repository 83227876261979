




































import {
  computed,
  defineComponent,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';

import productStatuses from '~/diptyqueTheme/config/productStatuses';
import { hasUniqueCapacity } from '~/diptyqueTheme/helpers/product/attributes';
import { getProductCapacity } from '~/diptyqueTheme/helpers/productGetters';
import type { CategoryInterface } from '~/modules/catalog/category/types';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'VaimoRelatedProductCard',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue')
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    currentProductSku: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { getProductDetails } = useProduct();
    const router = useRouter();
    const { app } = useContext();

    const isActive = computed(() => {
      return props.item.sku === props.currentProductSku;
    });

    const getProductBySKU = async () => {
      const result = await getProductDetails({
        filter: {
          sku: {
            eq: props.item.sku
          }
        }
      });
      const product = result?.items?.[0];
      const link = `${productGetters.getSlug(
        product as Product,
        product?.categories?.[0] as CategoryInterface
      )}`;

      await router.push(app.localePath(link));
    };

    const isOutOfStock = computed(
      () =>
        props.item.stock_status === productStatuses.out_of_stock ||
        !props.item.is_salable
    );

    return {
      isActive,
      isOutOfStock,
      getProductBySKU,
      getProductCapacity,
      hasUniqueCapacity
    };
  }
});
